import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Medication, Patient, PatientIdentity } from '@mapuilabs/hv-interfaces';
import { EVidalAllergyType } from '@mapuilabs/hv-interfaces/dist/enums/alergies.enum';
import { EHepaticInsufficiency } from '@mapuilabs/hv-interfaces/dist/enums/hepatic-insufficiency.enum';
import { Dosage, Interval, Period } from '@services/vidal/entities/dosage';
import { DrugPrescriptionLine } from '@services/vidal/entities/drug-prescription';
import { PriscaRequest } from '@services/vidal/entities/prisca-request';
import { PriscaResultType } from '@services/vidal/enum/prisca-result-type';
import { VidalMapper } from '@services/vidal/vidal.mapper';
import { EDosageEquivalent, EDurationUnit } from '@shared/select-posology/posology.const';
import dayjs from 'dayjs';
import { Dosage as FhirDosage } from 'fhir/r4';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VidalPriscaService {
    constructor(
        private httpClient: HttpClient,
        private vidalMapper: VidalMapper
    ) {}

    getPrisca(
        request: PriscaRequest,
        hvKeys: boolean,
        type: PriscaResultType = PriscaResultType.HTML
    ): Observable<string> {
        const content = this.requestToString(request);
        const options = {
            responseType: 'text' as 'json',
            params: {
                type: type
            }
        };
        return this.httpClient.post<string>(`vidal/alerts${hvKeys ? '/hv' : ''}`, { content: content }, options);
    }

    public requestToString(request: PriscaRequest): string {
        return `<?xml version="1.0" encoding="UTF-8"?>
                    <prescription>
                         <!-- This configuration only affects Prisca calls. -->
                         ${this.serializeSeverities()}
                         ${this.serializePatient(request.patient)}
                         ${this.serializePrescriptions(request.prescriptions)}
                    </prescription>`;
    }

    public parsePosology(posology: FhirDosage): Dosage {
        const dosage = new Dosage();
        if (posology.doseAndRate[0].doseRange?.high?.value) {
            dosage.dose = posology.doseAndRate[0].doseRange.high.value;
            dosage.unitId = this.vidalMapper.mapToVidalUnitId(
                posology.doseAndRate[0].doseRange.high.unit as EDosageEquivalent
            );
        } else {
            dosage.dose = posology.doseAndRate[0].doseQuantity.value;
            dosage.unitId = this.vidalMapper.mapToVidalUnitId(
                posology.doseAndRate[0].doseQuantity.unit as EDosageEquivalent
            );
        }
        const interval = new Interval();
        interval.min = posology.timing.repeat.period;
        interval.max = posology.timing.repeat.period;
        interval.unitId = this.vidalMapper.mapToVidalIntervalUnitId(
            posology.timing.repeat.durationUnit as EDurationUnit
        );
        return dosage;
    }

    public parsePrescription(vidalId: string, drug: Medication, posologies: FhirDosage[]): DrugPrescriptionLine {
        const dosages: Dosage[] = [];
        let unitId = null;
        let startDate = null;
        let endDate = null;
        let duration = null;
        let durationType = null;
        let frequencyType = null;
        posologies.forEach((poso) => {
            const dosage = this.parsePosology(poso);
            dosages.push(dosage);
            if (unitId == null) {
                unitId = dosage.unitId;
            }
            if (startDate == null) {
                startDate = poso.timing?.repeat?.boundsPeriod?.start;
            }
            if (endDate == null) {
                endDate = poso.timing?.repeat?.boundsPeriod?.end;
            }
            if (duration == null) {
                duration = poso.timing?.repeat?.boundsDuration?.value;
            }
            if (durationType == null) {
                durationType = poso.timing?.repeat?.boundsDuration?.unit;
            }
            if (frequencyType == null) {
                frequencyType = poso.timing?.repeat?.periodUnit;
            }
        });
        const totalDose = dosages.reduce((acc, currentValue) => {
            return acc + (currentValue.dose || 0);
        }, 0);
        const period = new Period();
        if (startDate != null) {
            period.startDate = startDate;
        }
        if (endDate != null) {
            period.endDate = endDate;
        }
        durationType = this.vidalMapper.mapToVidalDurationType(durationType as EDurationUnit);
        frequencyType = this.vidalMapper.mapToVidalFrequencyType(frequencyType);
        return new DrugPrescriptionLine(
            vidalId,
            unitId,
            totalDose,
            duration,
            durationType,
            frequencyType,
            dosages,
            period
        );
    }

    private serializeSeverities(): string {
        return `<priscaConfig>
                      <selectedTab>redundancyAlerts</selectedTab>
                      <selectedSeverities>
                           <selectedSeverity>SEVERITY_I</selectedSeverity>
                           <selectedSeverity>SEVERITY_II</selectedSeverity>
                           <selectedSeverity>SEVERITY_III</selectedSeverity>
                           <selectedSeverity>SEVERITY_X</selectedSeverity>
                      </selectedSeverities>
                 </priscaConfig>`;
    }

    private serializePatient(patient: Patient): string {
        const dateOfBirth = `${
            patient.identity instanceof PatientIdentity
                ? `<dateOfBirth>${patient.identity.birthDate.toISOString()}</dateOfBirth>`
                : `<dateOfBirth xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const breastFeedingStartDate = `${
            patient.breastFeedingStartDate
                ? `<breastFeedingStartDate>${patient.breastFeedingStartDate?.toISOString()}</breastFeedingStartDate>`
                : `<breastFeedingStartDate xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const gender = `${
            patient.identity instanceof PatientIdentity
                ? `<gender>${this.vidalMapper.mapToVidalGender(patient.identity.gender)}</gender>`
                : `<gender xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const height = `${
            patient.height != null
                ? `<height>${patient.height}</height>`
                : `<height xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const weight = `${
            patient.weight != null
                ? `<weight>${patient.weight}</weight>`
                : `<weight xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const weeksOfAmenorrhea = `${
            patient.weeksOfAmenorrhea != null
                ? `<weeksOfAmenorrhea>${patient.weeksOfAmenorrhea}</weeksOfAmenorrhea>`
                : `<weeksOfAmenorrhea xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const creatin = `${
            patient.creatin != null
                ? `<creatin>${patient.creatin}</creatin>`
                : `<creatin xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const hepaticInsufficiency = `${
            patient.hepaticInsufficiency === EHepaticInsufficiency.MODERATE ||
            patient.hepaticInsufficiency === EHepaticInsufficiency.SEVERE ||
            patient.hepaticInsufficiency === EHepaticInsufficiency.NONE
                ? `<hepaticInsufficiency>${patient.hepaticInsufficiency.toUpperCase()}</hepaticInsufficiency>`
                : `<hepaticInsufficiency xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const allergiesXML = patient.allergies
            .filter((allergy) => allergy.type === EVidalAllergyType.ALLERGY)
            .map((allergy) => `<allergy>vidal://allergy/${allergy.id}</allergy>`);

        const moleculesXML = patient.allergies
            .filter((allergy) => allergy.type === EVidalAllergyType.MOLECULE)
            .map((allergy) => `<molecule>vidal://molecule/${allergy.id}</molecule>`);

        const pathologiesXML = patient.pathologies.map(
            (pathology) => `<pathology>vidal://cim10/${pathology.id}</pathology>`
        );

        const allergies = `${
            patient.allergies && allergiesXML.length
                ? `<allergies>${allergiesXML}</allergies>`
                : `<allergies xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const molecules = `${
            patient.allergies && moleculesXML.length
                ? `<molecules>${moleculesXML}</molecules>`
                : `<molecules xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        const pathologies = `${
            patient.pathologies && pathologiesXML.length
                ? `<pathologies>${pathologiesXML}</pathologies>`
                : `<pathologies xsi:nil="true" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"/>`
        }`;

        return `<patient>
                    ${dateOfBirth}
                    ${gender}
                    ${height}
                    ${weight}
                    ${breastFeedingStartDate}
                    ${weeksOfAmenorrhea}
                    ${creatin}
                    ${hepaticInsufficiency}
                    ${allergies}
                    ${molecules}
                    ${pathologies}
                </patient>`;
    }

    private serializePrescriptions(drugs: DrugPrescriptionLine[]): string {
        let output = '<prescription-lines>';
        drugs.forEach((drug) => {
            output += this.getPrescriptionLine(drug);
        });
        output += '</prescription-lines>';
        return output;
    }

    private getPrescriptionLine(drug: DrugPrescriptionLine): string {
        const part1 = `<drug>${drug.drug}</drug>
                    ${this.serializeTag('dose', `${this.getGlobalDosage(drug)}`)}
                    ${this.serializeTag('unitId', `${drug.unitId}`)}
                    ${this.serializeTag('duration', `${drug.duration}`)}
                    ${this.serializeTag('durationType', `${drug.durationType}`)}
                    ${this.serializeTag('frequencyType', `${drug.frequencyType}`)}
                    ${this.serializePeriod(drug.period)}`;

        let partDosages = null;
        if (drug.dosages != null) {
            partDosages = this.getDosageLine(drug.dosages);
        }
        const partFixe = `<status>ACTIVE</status>
                    <group>
                        <groupId>1</groupId>
                        <groupType>SAME_ORDER</groupType>
                    </group>`;
        return `<prescription-line>
                ${part1}
                ${partDosages ?? ''}
                ${partFixe}
                </prescription-line>`;
    }

    private getGlobalDosage(drug: DrugPrescriptionLine): number {
        if (drug.dosages == undefined || drug.dosages.length == 0) {
            return drug.dose;
        }
        return drug.dosages.map((e) => e.dose).reduce((sum, current) => sum + current, 0);
    }

    private getDosageLine(dosages: Dosage[]): string {
        let line = ``;
        dosages.forEach((dosage) => {
            line += `<dosage>
                        <dose>${dosage.dose}</dose>
                        <unitId>${dosage.unitId}</unitId>
                    </dosage>`;
        });
        return `<dosages>
                    ${line}
                </dosages>`;
    }

    private serializeTag(tagName: string, value?: string): string {
        if (value == undefined || value == 'undefined') {
            return `<${tagName} />`;
        } else {
            return `<${tagName}>${value}</${tagName}>`;
        }
    }

    private serializePeriod(period?: Period): string {
        if (period == undefined) {
            return '';
        }
        let result = '<period>';
        const formatDate = (d: Date) => dayjs(d).format('YYYY-MM-DD');
        if (period.startDate) {
            result += `<startDate>${formatDate(period.startDate)}</startDate>`;
        }
        if (period.endDate) {
            result += `<endDate>${formatDate(period.endDate)}</endDate>`;
        }
        result += '</period>';
        return result;
    }
}
